<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input v-model="listQuery.nickname" placeholder="请输入昵称" style="width: 200px" class="filter-item" clearable />
      <el-date-picker v-model="dateArr" type="datetimerange" :default-time="['00:00:00', '23:59:59']" align="right" value-format="yyyy-MM-dd HH:mm:ss"
        unlink-panels range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" @change="changeDate" class="filter-item" style="display: inline-flex">
      </el-date-picker>
      <el-button class="filter-item" type="primary" @click="handleFilter">
        搜索
      </el-button>
      <el-button class="filter-item" type="primary" @click="addvideo('')">
        新增
      </el-button>
      <el-dialog :visible.sync="dialogVisible" :append-to-body="true" style="margin:10px 0px">
        <p >标题</p>
        <el-input v-model="videoadd.title" placeholder="请输入标题" type="text" style="width: 100px,display:blck," width="100px"></el-input>
        <el-radio v-model="radio" label="1" style="margin:20px 0px">本地上传</el-radio>
        <el-radio v-model="radio" label="2" style="margin:20px 10px">网络链接</el-radio>
        <uploadVideo v-if="radio=='1'" v-model="videoadd.link" style="width: 550px,display:inline-block;margin:10px 0px" width="350px" height="150px"
          @update="update">
        </uploadVideo>
        <el-input v-else  v-model="videoadd.link" placeholder="请输入网络链接" style="width: 550px,display:inline-block;" @change='$forceUpdate()'></el-input>

        <p>视频序号</p>
        <el-input v-model="videoadd.sort_weight" placeholder="请输入视频序号" type="Number" style="width: 100px,display:blck,"></el-input>

        <el-button @click="cancel()" style="margin:10px 0">取 消</el-button>
        <el-button type="primary" @click="determine()">确 定</el-button>
      </el-dialog>
    </div>

    <el-table v-loading="listLoading" :data="list" element-loading-text="Loading" border fit highlight-current-row>
      <el-table-column label="序号" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.$index + 1 }}
        </template>
      </el-table-column>
      <el-table-column label="ID" width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.id }}
        </template>
      </el-table-column>
      <el-table-column label="标题" min-width="110" align="center">
        <template slot-scope="scope">
          {{ scope.row.title }}
        </template>
      </el-table-column>
      <el-table-column label="类型" min-width="95" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.type == 1">本地视频</el-tag>
          <el-tag v-else>网络链接</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="视频" min-width="270" align="center">
        <template slot-scope="scope">
          <!-- <uploadVideo
          v-model="scope.row.link "
          style="width: 550px,display:inline-block;"
          width="400px"
          height="150px">
        </uploadVideo> -->
          <video :src="scope.row.link" v-if="scope.row.type == 1" height="150px"></video>
          <el-link href="https://element.eleme.io" target="_blank" v-else> {{ scope.row.link }}</el-link>
        </template>

      </el-table-column>

       <el-table-column label="序号" min-width="95" align="center">
        <template slot-scope="scope">
          {{ scope.row.sort_weight }}
        </template>
      </el-table-column>

      <el-table-column label="操作" width="200" align="center" class-name="small-padding fixed-width" fixed="right">
        <template slot-scope="scope">
          <el-button size="mini" type="primary" @click="addvideo(scope.row)">修改
          </el-button>
          <el-button size="mini" type="danger" @click="deletevideo(scope.row.id)">删除
          </el-button>
        </template>
      </el-table-column>

    </el-table>

    <div class="pagination-container">
      <el-pagination :current-page="listQuery.page" :page-sizes="[10, 20, 30, 50]" :page-size="listQuery.limit" :total="total" background
        layout="total, sizes, prev, pager, next, jumper" @size-change="handleSizeChange" @current-change="handleCurrentChange" />
    </div>
  </div>
</template>
<script>
import request from '@/utils/request'
import uploadVideo from '@/components/UploadVideo/index.vue'
export default {
  components: {
    uploadVideo,
  },
  data() {
    return {
      radio: '1',
      dialogVisible: false,
      dateArr: null,
      list: [],
      total: null,
      listLoading: false,
      listQuery: {
        page: 1,
        limit: 10,
        music_score_id: '',
        start_datetime: '',
        end_datetime: '',
        nickname: '',
      },
      videoadd: {
        id: '',
        music_score_id: '',
        title: '',
        type: 1,
        link: '',
        sort_weight: '',
      },
      date: {
        start_datetime: '',
        end_datetime: '',
      },
      dateArr: [],
    }
  },
  created() {
    this.listQuery.music_score_id = this.$route.query.id
    this.videoadd.music_score_id = this.$route.query.id
    this.getList()
  },
  mounted() {},
  watch: {
    dateArr(newVal, oldVal) {
      if (newVal) {
        this.listQuery.start_at = newVal[0]
        this.listQuery.end_at = newVal[1]
      } else {
        this.listQuery.start_at = ''
        this.listQuery.end_at = ''
      }
    },
    // 'videoadd.link'(newVal, oldVal) {
    //   this.videoadd.link = newVal
    // },
  },
  methods: {
    cancel(){
      this.dialogVisible = false;
      this.$forceUpdate();
    },  
    update() {
      this.$forceUpdate()
    },
    deletevideo(val) {
      this.$confirm('此操作将删除该视频, 是否继续?', '提示', {
        type: 'warning',
      })
        .then(() => {
          request({
            url: '/api/backend/musicScore/videoDel',
            method: 'post',
            data: { id: val },
          }).then(() => {
            this.getList()
            this.$message({
              type: 'success',
              message: '操作成功',
            })
          })
        })
        .catch(() => {})
    },
    determine() {
      console.log(this.videoadd.link)
      if (this.videoadd.title == undefined||this.videoadd.title == '') {
        this.$message.error('标题不能为空')
        return
      }
      if (this.videoadd.link == '') {
        this.$message.error('视频内容不能为空')
        return
      }
      if (this.videoadd.sort_weight == '') {
        this.$message.error('序号不能为空')
        return
      }
      this.dialogVisible = true
      if (this.radio == '1') {
        this.videoadd.type = 1
      } else {
        this.videoadd.type = 2
      }
      request({
        url: '/api/backend/musicScore/videoStore',
        method: 'post',
        params: this.videoadd,
      }).then((response) => {
        this.dialogVisible = false
        this.listLoading = false
        this.getList()
      })
    },
    addvideo(row) {
      this.radio = '1'
      if (row != '') {
        if (row.type == 2) {
          this.radio = '2'
        }
        this.videoadd = row
        console.log(this.videoadd)
      } else {
        // this.videoadd = {}
        this.videoadd.music_score_id = this.$route.query.id;
        this.videoadd.id = '';
        
        this.videoadd.title = '';
        this.videoadd.link = '';
        this.videoadd.sort_weight = '';
        console.log(this.videoadd.link);
        this.$forceUpdate();
      }
      this.dialogVisible = true

    },
    changeDate(data) {
      this.dateArr = data
      this.listQuery.start_at = data[0]
      this.listQuery.end_at = data[1]
    },
    getList() {
      this.listLoading = true
      console.log(this.videoadd)

      request({
        url: '/api/backend/musicScore/videoList',
        method: 'get',
        params: this.listQuery,
      }).then((response) => {
        console.log(response)
        this.list = response.data.data
        this.total = response.data.total
        this.listLoading = false
      })
    },
    handleFilter() {
      this.listQuery.page = 1
      this.getList()
    },
    handleSizeChange(val) {
      this.listQuery.limit = val
      this.getList()
    },
    handleCurrentChange(val) {
      this.listQuery.page = val
      this.getList()
    },
  },
}
</script>
<style lang="scss" scoped>
</style>